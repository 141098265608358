import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GrUserManager } from "react-icons/gr";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Navbar,
  Modal,
  Dropdown,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CiSearch } from "react-icons/ci";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useSelector } from "react-redux";
import HeaderNav from "./HeaderNav";
import Logo from "../images/Logo Png-01.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Quate from "../images/quate.png";

const Header = () => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [brands, setBrands] = useState([]);

  const isLoggedIn = useSelector((state) => state.auth.user);
  const userDetails = useSelector((state) => state.auth.user);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modalHandleClose = () => setModalShow(false);
  const modalHandleShow = () => setModalShow(true);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const baseUrl =
          process.env.REACT_APP_BASE_URL ||
          "https://backend.bolt-corp.devhub.digtalsdesigns.com";
        const response = await axios.get(`${baseUrl}/api/brands`);
        // let baseUrl = process.env.REACT_APP_BASE_URL;
        // const response = await axios.get(`${baseUrl}/api/brands`);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    fetchBrands();
  }, []);

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    const searchValue = event.target.elements.search.value;

    if (searchValue) {
      navigate(`/search-results?search=${searchValue}`);
    }
    try {
      const response = await axios.get(`${baseUrl}/api/search-products`, {
        params: { search: searchValue },
      });

      if (response.data && response.status === 200) {
        console.log(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(error.response.data.message);
      } else {
        console.error("Error fetching products:", error);
      }
    }
  };

  return (
    <>
      <Row>
        <Col className="col-12 col-lg-12">
          <Navbar expand="lg" className="bg-body-tertiary nav_bg">
            <Container style={{ maxWidth: "1370px" }}>
              <Col md={2}>
                <Navbar.Brand href="/" className="logo_name">
                  <img src={Logo} alt="Logo" />
                </Navbar.Brand>
              </Col>
              <Col md={10}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className="nav_links nav_bar_a"
                >
                  <Button
                    variant=""
                    className="Offcanvas-btn"
                    onClick={handleShow}
                  >
                    <HiOutlineMenuAlt3 />
                  </Button>
                  <NavLink to="/" className="nav_link">
                    Home
                  </NavLink>
                  <NavLink to="/about" className="nav_link">
                    About
                  </NavLink>
                  <NavLink to="/WizardScreen" className="nav_link">
                    Wizard Screen
                  </NavLink>
                  <NavLink to="/shop" className="nav_link">
                    Shop
                  </NavLink>
                  <div className="brand_dropdown">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        Brands
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {brands.map((brand) => (
                          <Dropdown.Item
                            as={NavLink}
                            to={`/brands/${brand.brand_id}`}
                            key={brand.brand_id}
                          >
                            {brand.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NavLink to="/cabinet" className="nav_link">
                    US Cabinet
                  </NavLink>
                  <NavLink to="contact" className="nav_link">
                    Contact us
                  </NavLink>
                  {/* <NavLink to="contact" className="nav_link">
                  <img src={Quate} alt="" />
                  </NavLink> */}
                  <div className="client-img">
                    {isLoggedIn && userDetails?.profile_picture ? (
                      <img
                        src={`https://backend.bolt-corp.devhub.digtalsdesigns.com/storage/app/public/${userDetails.profile_picture}`}
                        alt="User Profile"
                      />
                    ) : null}
                  </div>
                  {isLoggedIn ? (
                    <NavLink to="dashboard/overview">
                      <i className="header_input_icon">
                        <GrUserManager />
                      </i>
                    </NavLink>
                  ) : (
                    <NavLink to="/login" className="client-hover1">
                      Client Login
                    </NavLink>
                  )}
                  <HeaderNav />
                  <div className="header_input input-group-new">
                    <InputGroup className="">
                      <button onClick={modalHandleShow}>
                        <CiSearch />
                      </button>
                    </InputGroup>
                  </div>

                  <Modal
                    className="search-modal"
                    show={modalShow}
                    fullscreen={fullscreen}
                    onHide={modalHandleClose}
                  >
                    <Modal.Header closeButton />
                    <form onSubmit={handleSearchSubmit}>
                      <input
                        className="search-input"
                        placeholder="Search Product"
                        name="search"
                      />
                      <button type="submit" className="search-btn">
                        Search
                      </button>
                    </form>
                  </Modal>

                  <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Lorem ipsum</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eligendi esse molestias repellat provident sequi quam
                      repellendus excepturi minus culpa quaerat molestiae,
                      corrupti, laudantium expedita reiciendis officiis
                      consequuntur eaque voluptatem. Repellat?
                    </Offcanvas.Body>
                  </Offcanvas>
                </Navbar.Collapse>
              </Col>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import BannerImg from "../../images/shop-banner.png";
import ProductImg from "../../images/product-1.png";
import ProductImg2 from "../../images/product-2.png";
import ProductImg3 from "../../images/product-3.png";
import ProductSlide2 from "../../images/product-slide2.png";
import HeaderImage2 from "../../images/banner-side -image.png";
import { FaAngleDown, FaStar } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import { FaAngleRight, FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loading from "../../components/Loading ";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../../Redux/actions/settingsActions";

const CreteCast = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [limit, setLimit] = useState(3);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [categories, setCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { settings, error } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const filterProductsByPrice = () => {
    const filtered = products.filter((product) => {
      const productPrice = parseFloat(product.price);
      const min = minPrice ? parseFloat(minPrice) : 0;
      const max = maxPrice ? parseFloat(maxPrice) : Infinity;
      return productPrice >= min && productPrice <= max;
    });
    setFilteredProducts(filtered);
    setCurrentPage(0); // Reset to the first page
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleCategorySelect = (category) => {
    const isSelected = selectedCategories.includes(category);
    const updatedCategories = isSelected
      ? selectedCategories.filter((cat) => cat !== category)
      : [...selectedCategories, category];
    setSelectedCategories(updatedCategories);

    const filtered = products.filter((product) =>
      updatedCategories.includes(product.category)
    );
    setFilteredProducts(updatedCategories.length ? filtered : products);
    setCurrentPage(0); // Reset to the first page
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const baseUrl =
          process.env.REACT_APP_BASE_URL ||
          "https://backend.bolt-corp.devhub.digtalsdesigns.com";
        const response = await axios.get(baseUrl + "/api/show-products");
        // let baseUrl = process.env.REACT_APP_BASE_URL;
        // const response = await axios.get(baseUrl + "/api/show-products");
        if (Array.isArray(response.data.data)) {
          setProducts(response.data.data);
          setFilteredProducts(response.data.data);
        }
        const uniqueCategories = Array.from(
          new Set(response.data.map((item) => item.category))
        );
        const formattedCategories = uniqueCategories.map((category, index) => ({
          id: index + 1,
          category,
        }));
        setCategories(formattedCategories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await axios.get(baseUrl + "/api/show-categories");
        if (Array.isArray(response.data.data)) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    filterProductsByPrice();
  }, [minPrice, maxPrice, products]);

  const pricePoints = [1200, 1301, 1252, 1287, 12584];
  const maxPricePoint = Math.max(...pricePoints);
  const minPricePoint = Math.min(...pricePoints);

  const productsPerPage = 3;
  const indexOfLastProduct = (currentPage + 1) * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = Array.isArray(filteredProducts)
    ? filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
    : [];

  // if (loading) {
  //   return (
  //     <div className="spinner-container">
  //       <Loading />
  //     </div>
  //   );
  // }

  // PAGE CMS
  const crete_banner_title1 = settings.find(
    (setting) => setting.name === "crete_banner_title1"
  );
  const crete_banner_title2 = settings.find(
    (setting) => setting.name === "crete_banner_title2"
  );
  const crete_banner_text = settings.find(
    (setting) => setting.name === "crete_banner_text"
  );
  const crete_banner_image = settings.find(
    (setting) => setting.name === "crete_banner_image"
  );
  const crete_banner_image_text = settings.find(
    (setting) => setting.name === "crete_banner_image_text"
  );
  const crete_banner_image2 = settings.find(
    (setting) => setting.name === "crete_banner_image2"
  );

  const baseImageUrl =
    "https://backend.bolt-corp.devhub.digtalsdesigns.com/public/uploads/media/";
  const creteimageUrl = crete_banner_image?.value
    ? `${baseImageUrl}${crete_banner_image.value}`
    : "";
  const creteimageUrl2 = crete_banner_image2?.value
    ? `${baseImageUrl}${crete_banner_image2.value}`
    : "";

  return (
    <>
      <SectionHeader
        title={crete_banner_title1?.value}
        image={HeaderImage}
        image2={HeaderImage2}
        title2={crete_banner_title2?.value}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: crete_banner_text?.value,
            }}
          />
        }
      />

      {/* SIDE BAR START */}

      <Container>
        <div className="shop_detail_main">
          <Row>
            <Col md={4}>
              <div className="sidebar">
                <div className="stock">
                  <ul>
                    <li>
                      <FaAngleDown />
                      <span>Stock Status</span>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        In Stock
                        <p className="stock-count">(1347)</p>
                      </label>
                    </li>
                  </ul>
                </div>

                <div className="price">
                  <ul>
                    <li>
                      <label>
                        <input
                          type="number"
                          value={minPrice}
                          onChange={handleMinPriceChange}
                          placeholder="$ 180"
                        />
                      </label>
                      <label>
                        <input
                          type="number"
                          value={maxPrice}
                          onChange={handleMaxPriceChange}
                          placeholder="$ 180"
                        />
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="range"
                          value={limit}
                          onChange={handleLimitChange}
                          min={minPricePoint}
                          max={maxPricePoint}
                          step={
                            (maxPricePoint - minPricePoint) /
                            (pricePoints.length - 1)
                          }
                          className="slim-range-slider"
                          list="pricePoints"
                        />
                      </label>
                      <datalist id="pricePoints">
                        {pricePoints.map((point, index) => (
                          <option key={index} value={point} />
                        ))}
                      </datalist>
                    </li>
                    <li>
                      <div className="price-labels">
                        {pricePoints.map((point, index) => (
                          <span key={index} className="price-label">
                            {point}
                          </span>
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="category-1">
                  <ul>
                    <li onClick={toggleDropdown} style={{ cursor: "pointer" }}>
                      {isDropdownOpen ? <FaAngleDown /> : <FaAngleRight />}
                      <span>CATEGORIES</span>
                    </li>
                    {isDropdownOpen &&
                      categories
                        .filter(
                          ({ translation }) => translation && translation.name
                        ) // Ensures only categories with a name are shown
                        .map(({ id, translation }) => (
                          <li key={id}>
                            <button className="category_btn">
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleCategorySelect(translation.name)
                                }
                                checked={selectedCategories.includes(
                                  translation.name
                                )}
                              />
                              <p>{translation.name}</p>
                              <p className="stock-count">(12)</p>
                            </button>
                          </li>
                        ))}
                  </ul>
                </div>
                <div className="stock">
                  <ul>
                    <li>
                      <FaAngleDown />
                      <span>PRODUCTTYPE</span>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Range
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                  </ul>
                </div>

                <div className="category-2">
                  <ul>
                    <li>
                      <FaAngleDown />
                      <span>Size</span>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        24 INCH
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        24 INCH
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        24 INCH
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        24 INCH
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        24 INCH
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                  </ul>
                </div>

                <div className="stock">
                  <ul>
                    <li>
                      <FaAngleDown />
                      <span>Colour/Finish</span>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Lorem ipsum
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Lorem ipsum
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Lorem ipsum
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Lorem ipsum
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Lorem ipsum
                        <p className="stock-count">(12)</p>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="banner-img">
                <img src={creteimageUrl} alt="" />
                <div className="banner-title">
                  <h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: crete_banner_image_text?.value,
                      }}
                    />
                  </h1>
                </div>
              </div>

              {/* Product Section 1 */}

              <div className="products">
                <Row>
                  {Array.isArray(currentProducts) &&
                    currentProducts
                      .slice(0, 3)
                      .map(
                        ({
                          id,
                          price,
                          description,
                          slug,
                          name,
                          category,
                          sku,
                          images,
                        }) => {
                          const productImage = images.find(
                            (image) => image.sku === sku
                          );
                          let cleanedImageUrl = productImage
                            ? productImage.image_url
                            : "";
                          return (
                            <Col md={4} key={id}>
                              <div className="product-card">
                                <div className="Image">
                                  <Link to={`/single-product/${id}`}>
                                    {cleanedImageUrl && (
                                      <img src={cleanedImageUrl} alt={slug} />
                                    )}
                                  </Link>
                                </div>
                                <div className="product-title">
                                  <span>{name}</span>
                                  {[...Array(5)].map((_, i) => (
                                    <i key={i}>
                                      <FaStar />
                                    </i>
                                  ))}
                                  <div className="poduct-price">
                                    <span>${price}</span>
                                    <p>${price}</p>
                                  </div>
                                  <strong>{category}</strong>
                                </div>
                                <a href={/single-product/ + id}>
                                  Order Custom Product
                                </a>
                              </div>
                            </Col>
                          );
                        }
                      )}
                </Row>
              </div>

              {/* product-slide Section 2 */}

              <div className="product-slide">
                <Row>
                  <Col md={12}>
                    <div className="product_slide_marquee">
                      <marquee behavior="smooth" direction="left">
                        <span>NEW ARRIVALS</span>
                        <span>NEW ARRIVALS</span>
                        <span>NEW ARRIVALS</span>
                        <span>NEW ARRIVALS</span>
                        <span>NEW ARRIVALS</span>
                        <span>NEW ARRIVALS</span>
                      </marquee>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Product Section 3 */}

              <div className="products">
                <h2>SHOP BY BRAND</h2>
                <Row>
                  {Array.isArray(currentProducts) &&
                    currentProducts
                      .slice(0, 3)
                      .map(
                        ({
                          id,
                          price,
                          description,
                          slug,
                          name,
                          category,
                          sku,
                          images,
                        }) => {
                          const productImage = images.find(
                            (image) => image.sku === sku
                          );
                          let cleanedImageUrl = productImage
                            ? productImage.image_url
                            : "";
                          return (
                            <Col md={4} key={id}>
                              <div className="product-card">
                                <div className="Image">
                                  <Link to={`/single-product/${id}`}>
                                    {cleanedImageUrl && (
                                      <img src={cleanedImageUrl} alt={slug} />
                                    )}
                                  </Link>
                                </div>
                                <div className="product-title">
                                  <span>{name}</span>
                                  {[...Array(5)].map((_, i) => (
                                    <i key={i}>
                                      <FaStar />
                                    </i>
                                  ))}
                                  <div className="poduct-price">
                                    <span>${price}</span>
                                    <p>${price}</p>
                                  </div>
                                  <strong>{category}</strong>
                                </div>
                                <a href={/single-product/ + id}>
                                  Order Custom Product
                                </a>
                              </div>
                            </Col>
                          );
                        }
                      )}
                </Row>
              </div>
              {/* Product Section 4 */}

              <div className="products">
                <h2>BEST SELLER</h2>
                <Row>
                  {currentProducts.map(
                    ({
                      id,
                      slug,
                      price,
                      description,
                      category,
                      name,
                      sku,
                      images,
                    }) => {
                      const productImage = images.find(
                        (image) => image.sku === sku
                      );
                      let cleanedImageUrl = productImage
                        ? productImage.image_url
                        : "";
                      return (
                        <Col md={4} key={id}>
                          <div className="product-card">
                            <div className="Image">
                              <Link to={`/single-product/${id}`}>
                                {cleanedImageUrl && (
                                  <img src={cleanedImageUrl} alt={slug} />
                                )}
                              </Link>
                            </div>
                            <div className="product-title">
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              /> */}
                              <span>{name}</span>
                              {[...Array(5)].map((_, i) => (
                                <i key={i}>
                                  <FaStar />
                                </i>
                              ))}
                              <div className="poduct-price">
                                <span>${price}</span>
                                <p>${price}</p>
                              </div>
                              <strong>{category}</strong>
                            </div>
                            <a href={/single-product/ + id}>
                              Order Custom Product
                            </a>
                            <div className="percent1">
                              <p>10%</p>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  )}
                </Row>
              </div>

              {/* product-slide-2 Section 2 */}

              <div className="product-slide">
                <Row>
                  <Col md={12}>
                    <div className="product_slide">
                      <img src={creteimageUrl2} alt="" />
                      <div className="product-slide-text">
                        <span>Lorem ipsum dolor</span>
                        <h2>Shop New Arrivals</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur <br></br>{" "}
                          adipisicing elit. Voluptas
                        </p>
                        <a href="/">
                          Shop Now <FaArrowRight />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Product Section 5 */}

              {/* Product Section 6 */}

              <div className="products-last">
                <Row>
                  {currentProducts.map(
                    ({
                      id,
                      slug,
                      name,
                      price,
                      description,
                      category,
                      sku,
                      images,
                    }) => {
                      // const cleanedImageUrl = imagestwo.replace(/["\\[\]]/g, '')
                      const productImage = images.find(
                        (image) => image.sku === sku
                      );
                      let cleanedImageUrl = productImage
                        ? productImage.image_url
                        : "";
                      return (
                        <Col md={4} key={id}>
                          <div className="product-card">
                            <div className="Image">
                              <Link to={`/single-product/${id}`}>
                                <img src={cleanedImageUrl} alt={slug} />
                              </Link>
                            </div>
                            <div className="product-title">
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              /> */}
                              <span>{name}</span>
                              {[...Array(5)].map((_, i) => (
                                <i key={i}>
                                  <FaStar />
                                </i>
                              ))}
                              <div className="poduct-price">
                                <span>${price}</span>
                                <p>${price}</p>
                              </div>
                              <strong>{category}</strong>
                            </div>
                            <a href={/single-product/ + id}>
                              Order Custom Product
                            </a>
                            <div className="percent1">
                              <p>10%</p>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  )}
                </Row>
              </div>

              <ReactPaginate
                previousLabel={<FaArrowLeft />}
                nextLabel={<FaArrowRight />}
                breakLabel={"..."}
                pageCount={Math.ceil(filteredProducts.length / productsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default CreteCast;

import React from "react";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";

const Overview = () => {
  const userDetails = useSelector((state) => state.auth.user);
  return (
    <>
      <div className="dash-card about-bg">
        <Card className="dash-sub-card">
          <Card.Header as="h4" className="dash-card-header">
            Account Information
            <div className="client-img2">
              <img
                src={`https://backend.bolt-corp.devhub.digtalsdesigns.com/storage/app/public/${userDetails?.profile_picture}`}
                alt="images"
              />
            </div>
          </Card.Header>
          <Card.Body>
            <div className="card_body">
              <FaUser />
              <p>Name: </p>
              <strong>{userDetails?.name || "User Name"}</strong>
            </div>
            <div className="card_body">
              <MdEmail />
              <p>Email: </p>
              <strong>{userDetails?.email || "User Email"}</strong>
            </div>
            <div className="card_body">
              <FaPhone />
              <p>Phone: </p>
              <strong>{userDetails?.phone || "User Phone"}</strong>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Overview;

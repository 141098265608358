import "./login.css";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "../../Redux/actions/authActions";

const Login = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      Swal.fire("Please fill out all fields.");
      return;
    }

    try {
      let baseUrl =
        process.env.REACT_APP_BASE_URL ||
        "https://backend.bolt-corp.devhub.digtalsdesigns.com";
      const response = await axios.post(baseUrl + "/api/login", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      if (data.error) {
        dispatch(loginFailure(data.error));
        Swal.fire(data.error);
      } else {
        dispatch(loginSuccess(data.user));
        localStorage.setItem("user-info", JSON.stringify(data.user));
        Swal.fire("You have successfully login");
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(loginFailure("Please Login First."));
    }
  };

  return (
    <>
      <section className="login-bg">
        <div className="main_login_signup">
          <Container>
            <Row className="row_login_signup">
              <Col lg={6}>
                <div className="main_lognin">
                  <form onSubmit={handleLogin}>
                    <h1>Login to your account</h1>
                    <div className="row">
                      <Col lg={12}>
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter Your Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={12}>
                        <input
                          type="password"
                          name="password"
                          placeholder="Enter Your Password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg={12}>
                        <button type="submit" className="theme-btn">
                          Login
                        </button>
                      </Col>
                    </div>
                    <a href="/register">Create an Account</a>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Login;

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionHeader from '../../components/SectionHeader'
import { Accordion,  Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../Redux/actions/settingsActions';

const Faqs = () => {
    // ************ Start CMS ************
    const dispatch = useDispatch();
    const { settings } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    
    const faq_Banner = settings.find(setting => setting.name === 'faq_Banner');
    const faq_Content_q1 = settings.find(setting => setting.name === 'faq_Content_q1');
    const faq_Content_q2 = settings.find(setting => setting.name === 'faq_Content_q2');
    const faq_Content_q3 = settings.find(setting => setting.name === 'faq_Content_q3');
    const faq_Content_q4 = settings.find(setting => setting.name === 'faq_Content_q4');
    const faq_Content_q5 = settings.find(setting => setting.name === 'faq_Content_q5');
    const faq_Content_q6 = settings.find(setting => setting.name === 'faq_Content_q6');
    const faq_Content_q7 = settings.find(setting => setting.name === 'faq_Content_q7');
    const faq_Content_q8 = settings.find(setting => setting.name === 'faq_Content_q8');
    const faq_Content_a1 = settings.find(setting => setting.name === 'faq_Content_a1');
    const faq_Content_a2 = settings.find(setting => setting.name === 'faq_Content_a2');
    const faq_Content_a3 = settings.find(setting => setting.name === 'faq_Content_a3');
    const faq_Content_a4 = settings.find(setting => setting.name === 'faq_Content_a4');
    const faq_Content_a5 = settings.find(setting => setting.name === 'faq_Content_a5');
    const faq_Content_a6 = settings.find(setting => setting.name === 'faq_Content_a6');
    const faq_Content_a7 = settings.find(setting => setting.name === 'faq_Content_a7');
    const faq_Content_a8 = settings.find(setting => setting.name === 'faq_Content_a8');    
    const baseImageUrl = 'https://backend.bolt-corp.devhub.digtalsdesigns.com/public/uploads/media/';
    const imageUrl = faq_Banner?.value ? `${baseImageUrl}${faq_Banner.value}` : '';
    // ************ End CMS ************
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <SectionHeader title='Help & FAQs' image={imageUrl} PageLink='Help & FAQs'></SectionHeader>
            <section className="faq-bg">
                <Container>
                    <div className="faq_section">
                        <h2 className="faq_section_heading" data-aos="zoom-in-up" data-aos-duration="3000" data-aos-easing="linear">Frequently <span>Ask</span> Questions</h2>
                        <div className='faq-accordion'>
                            <Accordion  flush >
                                <Accordion.Item  className='faq-Accordion-item' eventKey="0" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q1?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a1?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="1" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q2?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a2?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="2" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q3?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a3?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="3" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q4?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a4?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="4" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q5?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a5?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="5" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q6?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a6?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="6" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q7?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a7?.value}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='faq-Accordion-item' eventKey="7" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                                    <Accordion.Header className='faq-accordion-header'>{faq_Content_q8?.value}</Accordion.Header>
                                    <Accordion.Body className='faq-accordion-body'>{faq_Content_a8?.value}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                </Container>
            </section>
        </>
    )
}

export default Faqs
import React, { useEffect, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import HeaderImage2 from "../../images/banner-side -image.png";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import RequestQuote from "../../components/RequestQuote";
import Information from "../../components/Information";
import Loading from "../../components/Loading ";
import { Link } from "react-router-dom";
import Image3 from "../../images/smartscreen-product-gallery5.jpg";
import Image4 from "../../images/WDC24562-scaled.jpg";

const WizardScreen = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <SectionHeader
        title="Wizard Screen"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>

      {/* SIDE BAR START */}
      <Container className="aling">
        <Row>
          <Col md={12}>
            <div className="products">
              <Row>
                <Col md={6}>
                  <div className="product-card">
                    <div className="product-title">
                      <span>SmartScreen</span>
                      <div className="poduct-price">
                        <span>Ideal for vertical screening solutions up to 12’ wide & 10’ high</span>
                      </div>
                    </div>
                    <div className="Image">
                      <Link to="https://www.wizardscreens.com/products/smartscreen/">
                        <img src={Image3} alt="" />
                      </Link>
                    </div>
                    <a href="https://www.wizardscreens.com/products/smartscreen/">
                      Learn More
                    </a>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="product-card">
                    <div className="product-title">
                      <span>SmartScreen Motorized</span>
                      <div className="poduct-price">
                        <span>Ideal for large outdoor areas up to 30’ wide & 16’ high</span>
                      </div>
                    </div>
                    <div className="Image">
                      <Link to="https://www.wizardscreens.com/products/smartscreen-motorized/">
                        <img src={Image4} alt="" />
                      </Link>
                    </div>
                    <a href="https://www.wizardscreens.com/products/smartscreen-motorized/">
                      Learn More
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs defaultActiveKey="information" id="uncontrolled-tab-example">
              <Tab eventKey="information" title="Information">
                <Information />
              </Tab>
              <Tab eventKey="request" title="Request a Quote">
              {/* <Tab eventKey="request" title="Order Custom Product"> */}
                <RequestQuote />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WizardScreen;

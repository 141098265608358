import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { PayPalButton } from 'react-paypal-button-v2'; // Make sure to install this package
import Swal from 'sweetalert2';

function MyPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('stripe'); // State to track selected payment method

  const handleSubmitStripe = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      // Handle the Stripe payment processing here
      // ...
    } 
  };

  // Function to handle PayPal payment submission
  const handleSuccessPayPal = (details, data) => {
    // Handle the successful PayPal payment here
    // ...
    Swal.fire('Success', 'Your PayPal payment was successful!', 'success');
  };

  const handleErrorPayPal = (error) => {
    // Handle PayPal payment errors here
    // ...
    Swal.fire('Error', 'There was an issue with your PayPal payment.', 'error');
  };

  return (
    <div>
      <h4>Payment Form</h4>
      <div>
        <input
          type="radio"
          name="paymentMethod"
          value="stripe"
          checked={paymentMethod === 'stripe'}
          onChange={() => setPaymentMethod('stripe')}
        />
        <label>Pay with Stripe</label>
      </div>
      <div>
        <input
          type="radio"
          name="paymentMethod"
          value="paypal"
          checked={paymentMethod === 'paypal'}
          onChange={() => setPaymentMethod('paypal')}
        />
        <label>Pay with PayPal</label>
      </div>

      {paymentMethod === 'stripe' && (
        <form onSubmit={handleSubmitStripe}>
          <CardElement />
          <button type="submit" disabled={!stripe}>Pay with Card</button>
        </form>
      )}

      {paymentMethod === 'paypal' && (
        <PayPalButton
          amount="0.01" // Set the amount for the PayPal payment
          onSuccess={handleSuccessPayPal}
          catchError={handleErrorPayPal}
        />
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default MyPaymentForm;
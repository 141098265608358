import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdPlayCircle } from "react-icons/io";

import Image from "../images/about-img-2.png";
import ImageText from "../images/about-us-text.png";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../Redux/actions/settingsActions";

const AboutUs = ({ hideClasses = false }) => {
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const about_title2 = settings.find(
    (setting) => setting.name === "about_title2"
  );
  const about_title = settings.find(
    (setting) => setting.name === "about_title"
  );
  const about_sub_title = settings.find(
    (setting) => setting.name === "about_sub_title"
  );
  const about_sub_title_two = settings.find(
    (setting) => setting.name === "about_sub_title_two"
  );
  const about_us_image_one = settings.find(
    (setting) => setting.name === "about_us_image_one"
  );
  const about_point_one = settings.find(
    (setting) => setting.name === "about_Point_one"
  );
  const about_point_two = settings.find(
    (setting) => setting.name === "about_point_two"
  );
  const free_shipping_minimum_amount = settings.find(
    (setting) => setting.name === "free_shipping_minimum_amount"
  );

  const baseImageUrl =
    "https://backend.bolt-corp.devhub.digtalsdesigns.com/public/uploads/media/";
  const imageUrl = about_us_image_one?.value
    ? `${baseImageUrl}${about_us_image_one.value}`
    : "";

  return (
    <>
      <div className="about-main">
        <Container>
          <div className="my-container about-img-bg">
            <Row>
              <Col>
                <div className="about-img">
                  <img
                    src={imageUrl}
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="3000"
                    data-aos-easing="linear"
                  />
                  <div
                    className="shadesx-screens"
                    data-aos="fade-right"
                    data-aos-duration="4000"
                    data-aos-easing="linear"
                  >
                    <div className="shadesx-screens-icon">
                      <IoMdPlayCircle />
                    </div>
                    <div>
                      <h5>
                        Shades<span>X</span>screens
                      </h5>
                      <p>Watch Video</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="about-text">
                  <p className="about-text-line">{about_title2?.value}</p>
                  <h1>{about_title?.value}</h1>
                  <p className="about-sub-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: about_sub_title_two?.value,
                      }}
                    />
                  </p>
                  <p>
                    <strong>{about_sub_title?.value}</strong>
                  </p>
                  <div className="about-sub-text-2">
                    <p>{about_point_one?.value}</p>
                    <p>{about_point_two?.value}</p>
                  </div>
                  {!hideClasses && (
                    <button className="custom_btn">
                      <Link to="#">Shop Now</Link>
                    </button>
                  )}
                </div>
              </Col>
              {!hideClasses && (
                <div className="active_outdoor_img">
                  <img src={ImageText} alt="" />
                </div>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;

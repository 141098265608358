import React, { useEffect, useState } from "react";
import OurBrands from "../../components/OurBrands";
import OurBestSell from "../../components/OurBestSell";
import HeroSlider from "../../components/HeroSlider";
import AboutUs from "../../components/AboutUs";
import Category from "../../components/Category";
import Collection from "../../components/Collection";
import ContactUs from "../../components/ContactUs";
import Blogs from "../../components/Blogs";
import Loading from "../../components/Loading ";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <HeroSlider />
      <AboutUs />
      <Category />
      <OurBrands />
      <Collection />
      <OurBestSell />
      <ContactUs />
      <Blogs />
    </>
  );
};

export default Home;

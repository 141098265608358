import React, { useEffect } from "react";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import Cards from "../images/Layer-7.png";

import { FaFacebookF, FaInstagramSquare, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../Redux/actions/settingsActions";
import FaceBook from "../images/facebook.png";
import Instagram from "../images/instagram.png";
import Twitter from "../images/twitter.png";

const Footer = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  // ************ CMS ************
  const Copyright = settings.find((setting) => setting.name === "Copyright");
  const footer_text = settings.find((setting) => setting.name === "footer_text");
  const footer_title = settings.find((setting) => setting.name === "footer_title");
  const footer_logo = settings.find((setting) => setting.name === "footer_logo");
  const baseImageUrl =
    "https://backend.bolt-corp.devhub.digtalsdesigns.com/public/uploads/media/";
  const Logo = footer_logo?.value ? `${baseImageUrl}${footer_logo.value}` : ""; // Construct the full image URL
  const fb_link = settings.find((setting) => setting.name === "fb_link");
  const tw_link = settings.find((setting) => setting.name === "tw_link");
  const in_link = settings.find((setting) => setting.name === "in_link");

  return (
    <>
      <footer>
        <section className="footer_section">
          <Container>
            <div className="my-container">
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col
                  sm={8}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                  data-aos-easing="linear"
                >
                  <div className="news">
                    <h2>GET STARTED WITH SHADE X SCREENS</h2>
                  </div>
                </Col>
                <Col
                  sm={4}
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  data-aos-easing="linear"
                >
                  <div className="">
                    <InputGroup className="footer_btn">
                      <Button className="card_btn2" id="button-addon2">
                        Get Start Now
                      </Button>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="footer_section2">
          <Container>
            <div className="my-container">
              <Row>
                <Col sm={3}>
                  <div className="footer_logo_section">
                    <h5>{footer_title?.value}</h5>
                    <p>
                    {footer_text?.value}
                    </p>
                    <div className="footer_icon">
                      <ul>
                        <li>
                          <Link to={fb_link?.value} target="_blank">
                            <img src={FaceBook} alt="FaceBook" />
                          </Link>
                        </li>
                        <li>
                          <Link to={tw_link?.value} target="_blank">
                            <img src={Twitter} alt="Twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to={in_link?.value} target="_blank">
                            <img src={Instagram} alt="Instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="footer-links">
                    <h5>Links</h5>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/ZlineKitchen">Featured</Link>
                      </li>
                      <li>
                        <Link to="/ZlineKitchen">Top Products </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="footer-links">
                    <h5>Quick Links</h5>
                    <ul>
                      <li>
                        <Link>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link>Terms & Condition</Link>
                      </li>
                      <li>
                        <Link>Return Policy</Link>
                      </li>
                      <li>
                        <Link>Shipping Policy</Link>
                      </li>
                      <li>
                        <Link>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="footer-links">
                    <h5>newsletter sign up</h5>
                    <ul>
                      <li>
                        <Link to="#">
                          Sign up for exclusive updates, <br /> new arrivals &
                          insider only disccounts
                        </Link>
                      </li>
                      <li>
                        <InputGroup className="footer_inputs">
                          <Form.Control placeholder="Enter your email address" />
                          <Button className="custom_btn2" id="button-addon2">
                            Sumnit
                          </Button>
                        </InputGroup>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="footer_section3">
          <Container>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col sm={6}>
                <p className="footer_section3_p">
                  {Copyright?.value}
                </p>
              </Col>
              <Col sm={6} className="footer_card">
                <Link to="/">
                  <img src={Cards} alt="" />
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </footer>
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../images/contact-us-1.png";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
  };
  return (
    <>
      <div className="Contact-main">
        <div className="marquee-container Contact-main-bg-img">
          <img className="" src={Image} alt="" />
        </div>
        <Container>
          <div className="my-container">
            <Row>
              <div className="contact-form">
                <h1>Contact Us</h1>
                <form className="contact_form" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6">
                      <input
                        type="text"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg="6" data-aos="fade-left" data-aos-duration="1000">
                      <input
                        type="text"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg="6" data-aos="fade-right" data-aos-duration="1000">
                      <input
                        type="number"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg="6" data-aos="fade-left" data-aos-duration="1000">
                      <input
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg="12" data-aos="fade-down" data-aos-duration="1000">
                      <textarea
                        type="Message"
                        rows="6"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </Col>
                  </Row>
                  <div className="contact_btn">
                    <button className="custom_btn_card">
                      <Link>Submit</Link>{" "}
                    </button>
                  </div>
                </form>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;

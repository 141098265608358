import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { fetchSettings } from "../Redux/actions/settingsActions";
import ReactPaginate from "react-paginate";
import HeaderImage from "../images/main-banner.png";
import HeaderImage2 from "../images/banner-side -image.png";
import {
  FaAngleDown,
  FaAngleRight,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa6";
import SectionHeader from "./SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading ";

const BrandProducts = () => {
  const { brandId } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const productsPerPage = 12; // Declare productsPerPage only once here
  const [isChecked, setIsChecked] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [limit, setLimit] = useState(3);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const { settings, error } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const filterProductsByPrice = () => {
    const filtered = products.filter((product) => {
      const productPrice = parseFloat(product.price);
      const min = minPrice ? parseFloat(minPrice) : 0;
      const max = maxPrice ? parseFloat(maxPrice) : Infinity;
      return productPrice >= min && productPrice <= max;
    });
    setFilteredProducts(filtered);
    setCurrentPage(0); // Reset to the first page
  };

  const handleCategorySelect = (category) => {
    const isSelected = selectedCategories.includes(category);
    const updatedCategories = isSelected
      ? selectedCategories.filter((cat) => cat !== category)
      : [...selectedCategories, category];
    setSelectedCategories(updatedCategories);

    const filtered = products.filter((product) =>
      updatedCategories.includes(product.category)
    );
    setFilteredProducts(updatedCategories.length ? filtered : products);
    setCurrentPage(0); // Reset to the first page
  };

  useEffect(() => {
    filterProductsByPrice();
  }, [minPrice, maxPrice, products]);

  const pricePoints = [1200, 1301, 1252, 1287, 12584];
  const maxPricePoint = Math.max(...pricePoints);
  const minPricePoint = Math.min(...pricePoints);

  const productsPerRow = 4;
  const indexOfLastProduct = (currentPage + 1) * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = Array.isArray(filteredProducts)
    ? filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
    : [];

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       const baseUrl =
  //         process.env.REACT_APP_BASE_URL ||
  //         "https://backend.bolt-corp.devhub.digtalsdesigns.com";
  //       const response = await axios.get(baseUrl + "/api/show-products");
  //       // let baseUrl = process.env.REACT_APP_BASE_URL;
  //       // const response = await axios.get(baseUrl + "/api/show-products");
  //       if (
  //         Array.isArray(response.data.data) &&
  //         response.data.data.length > 0
  //       ) {
  //         // If products exist, set them to state
  //         setProducts(response.data.data);
  //         setFilteredProducts(response.data.data);

  //         // Extract unique categories
  //         const uniqueCategories = Array.from(
  //           new Set(response.data.data.map((item) => item.category))
  //         );
  //         const formattedCategories = uniqueCategories.map(
  //           (category, index) => ({
  //             id: index + 1,
  //             category,
  //           })
  //         );
  //         setCategories(formattedCategories);
  //       } else {
  //         // If no products, set an empty array and show "No Product Found" message
  //         setProducts([]);
  //       }

  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching products:", error);
  //       setLoading(false);
  //     }
  //   };
  //   fetchProducts();
  // }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const baseUrl =
          process.env.REACT_APP_BASE_URL ||
          "https://backend.bolt-corp.devhub.digtalsdesigns.com";
        const response = await axios.get(baseUrl + "/api/show-categories");
        // let baseUrl = process.env.REACT_APP_BASE_URL;
        // const response = await axios.get(baseUrl + "/api/show-categories");
        if (Array.isArray(response.data.data)) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchBrandProducts = async () => {
      try {
        setLoading(true);
        const baseUrl =
          process.env.REACT_APP_BASE_URL ||
          "https://backend.bolt-corp.devhub.digtalsdesigns.com";
        const response = await axios.get(
          `${baseUrl}/api/show-products/brand/${brandId}`
        );
        // let baseUrl = process.env.REACT_APP_BASE_URL;
        // const response = await axios.get(
        //   `${baseUrl}/api/show-products/brand/${brandId}`
        // );
        setProducts(response.data.data);
        setFilteredProducts(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(`Error fetching products for brand ${brandId}:`, error);
        setProducts([]);
        setLoading(false);
      }
    };
    fetchBrandProducts();
  }, [brandId]);

  if (loading) {
    return (
      <div className="spinner-container">
        <Loading />
      </div>
    );
  }

  const offset = currentPage * productsPerPage;
  const displayedProducts = products.slice(offset, offset + productsPerPage);
  const pageCount = Math.ceil(products.length / productsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // PAGE CMS
  const banner_title1 = settings.find(
    (setting) => setting.name === "banner_title1"
  );
  const banner_title2 = settings.find(
    (setting) => setting.name === "banner_title2"
  );
  const banner_text = settings.find(
    (setting) => setting.name === "banner_text"
  );
  const banner_image = settings.find(
    (setting) => setting.name === "banner_image"
  );
  const banner_image_text = settings.find(
    (setting) => setting.name === "banner_image_text"
  );
  const banner_image2 = settings.find(
    (setting) => setting.name === "banner_image2"
  );

  const baseImageUrl =
    "https://backend.bolt-corp.devhub.digtalsdesigns.com/public/uploads/media/";
  const imageUrl = banner_image?.value
    ? `${baseImageUrl}${banner_image.value}`
    : "";
  const imageUrl2 = banner_image2?.value
    ? `${baseImageUrl}${banner_image2.value}`
    : "";

  const parseImageUrl = (url) => {
    try {
      const parsedUrl = JSON.parse(url);
      const formattedUrl = parsedUrl[0].replace("/storage/", "/storage/app/");
      return formattedUrl;
    } catch (e) {
      console.error("Error parsing image URL:", e);
      return null;
    }
  };

  return (
    <>
      <SectionHeader
        title={banner_title1?.value}
        image={HeaderImage}
        image2={HeaderImage2}
        title2={banner_title2?.value}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: banner_text?.value,
            }}
          />
        }
      />
      <div className="shop_detail_main">
        <h1>Zline Kitchen Products</h1>
        <p className="heading">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
          repudiandae aliquam maxime doloremque aut est. Ipsa ex quae asperiores
          voluptate repellendus inventore, velit similique odio vel molestias
          corporis voluptatem quidem?
        </p>
        <div className="brandclasss">
          <div className="sidebar">
            <div className="stock">
              <ul>
                <li>
                  <FaAngleDown />
                  <span>Stock Status</span>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    In Stock
                    <p className="stock-count">(1347)</p>
                  </label>
                </li>
              </ul>
            </div>
            <div className="price">
              <ul>
                <li>
                  <label>
                    <input
                      type="number"
                      value={minPrice}
                      onChange={handleMinPriceChange}
                      placeholder="$ 180"
                    />
                  </label>
                  <label>
                    <input
                      type="number"
                      value={maxPrice}
                      onChange={handleMaxPriceChange}
                      placeholder="$ 180"
                    />
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="range"
                      value={limit}
                      onChange={handleLimitChange}
                      min={minPricePoint}
                      max={maxPricePoint}
                      step={
                        (maxPricePoint - minPricePoint) /
                        (pricePoints.length - 1)
                      }
                      className="slim-range-slider"
                      list="pricePoints"
                    />
                  </label>
                  <datalist id="pricePoints">
                    {pricePoints.map((point, index) => (
                      <option key={index} value={point} />
                    ))}
                  </datalist>
                </li>
                <li>
                  <div className="price-labels">
                    {pricePoints.map((point, index) => (
                      <span key={index} className="price-label">
                        {point}
                      </span>
                    ))}
                  </div>
                </li>
              </ul>
            </div>
            <div className="category-1">
              <ul>
                <li onClick={toggleDropdown} style={{ cursor: "pointer" }}>
                  {isDropdownOpen ? <FaAngleDown /> : <FaAngleRight />}
                  <span>CATEGORIES</span>
                </li>
                {isDropdownOpen &&
                  categories
                    .filter(
                      ({ translation }) => translation && translation.name
                    ) // Ensures only categories with a name are shown
                    .map(({ id, translation }) => (
                      <li key={id}>
                        <button className="category_btn">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleCategorySelect(translation.name)
                            }
                            checked={selectedCategories.includes(
                              translation.name
                            )}
                          />
                          <p>{translation.name}</p>
                          <p className="stock-count">(12)</p>
                        </button>
                      </li>
                    ))}
              </ul>
            </div>
            <div className="stock">
              <ul>
                <li>
                  <FaAngleDown />
                  <span>PRODUCTTYPE</span>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    Range
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
              </ul>
            </div>

            <div className="category-2">
              <ul>
                <li>
                  <FaAngleDown />
                  <span>Size</span>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    24 INCH
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    24 INCH
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    24 INCH
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    24 INCH
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    24 INCH
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
              </ul>
            </div>

            <div className="stock">
              <ul>
                <li>
                  <FaAngleDown />
                  <span>Colour/Finish</span>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    Lorem ipsum
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    Lorem ipsum
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    Lorem ipsum
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    Lorem ipsum
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    Lorem ipsum
                    <p className="stock-count">(12)</p>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="products">
            <Row>
              {displayedProducts.length === 0 ? (
                <div className="no-product-found">
                  <p>No Product Found</p>
                </div>
              ) : (
                displayedProducts.map(
                  ({
                    id,
                    price,
                    slug,
                    name,
                    category,
                    sku,
                    images,
                    imagestwo,
                  }) => (
                    <Col md={3} key={id}>
                      <div className="product-card">
                        <div className="Image">
                          <Link to={`/single-product/${id}`}>
                            {images.length > 0 && images[0].image_url ? (
                              <img src={images[0].image_url} alt={slug} />
                            ) : (
                              <img
                                src={parseImageUrl(imagestwo) || ""}
                                alt={slug}
                              />
                            )}
                          </Link>
                        </div>
                        <div className="product-title">
                          <span>{name}</span>
                          <div className="product-price">
                            <span>${price}</span>
                          </div>
                          <strong>{category}</strong>
                        </div>
                      </div>
                    </Col>
                  )
                )
              )}
            </Row>
          </div>
        </div>
        <ReactPaginate
          previousLabel={<FaArrowLeft />}
          nextLabel={<FaArrowRight />}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
        />
      </div>
    </>
  );
};

export default BrandProducts;

import React from "react";

const Information = () => {
  return (
    <>
      <h1>Iformation</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi earum
        tempore excepturi molestiae. Quo hic soluta eius ea repudiandae enim
        totam, obcaecati dolorem. Quis ea exercitationem, debitis velit
        provident minima!
      </p>
    </>
  );
};

export default Information;

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PayPalButtonWithBoundary from '../PayPalButton/PayPalButton'
import StripePaymentComponent from '../stripePayment/StripePaymentComponent';
function PaymentOptions() {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    localStorage.setItem('selectedPaymentMethod',e.target.value);
  };

  return (
    <>
    <div>
    <Form.Group>
        <Form.Check
          type="radio"
          id="cashOnDeliveryOption"
          label="Cash on Delivery"
          name="paymentOption"
          value="cashOnDelivery"
          checked={selectedOption === 'cashOnDelivery'}
          onChange={handleOptionChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          type="radio"
          id="stripeOption"
          label="Online"
          name="paymentOption"
          value="stripe"
          checked={selectedOption === 'stripe'}
          onChange={handleOptionChange}
        />
      </Form.Group>

      {/* <Form.Group>
        <Form.Check
          type="radio"
          id="paypalOption"
          label="Paypal"
          name="paymentOption"
          value="paypal"
          checked={selectedOption === 'paypal'}
          onChange={handleOptionChange}
        />
      </Form.Group> */}      
    </div>


    {
    (selectedOption === 'stripe')? 
    <StripePaymentComponent />
    : <>
      {(selectedOption === 'paypal')? 
        <PayPalButtonWithBoundary /> : ''
      }
      </>
   }
    </>
  );
}

export default PaymentOptions;
import React from "react";
import Menu from "@mui/material/Menu";
import { Col, Table } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import NoImage from "../images/NoImage.jpg";
import { MdDelete } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { MdRemoveShoppingCart } from "react-icons/md";

const CartBody = ({ anchorEl, open, handleClose, getdata, dlt, price }) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {getdata.length ? (
        <div className="cart_body_details">
          <Table>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {getdata.map((e) => {
                const cleanSlug = e.slug.replace(/-/g, " ");
                let cleanedImageUrl = NoImage;

                if (e.imagestwo) {
                  cleanedImageUrl = e.imagestwo
                    .replace(/["\\[\]]/g, "") // Remove unwanted characters
                    .replace(/\/storage\/media/, "/storage/app/media");
                }

                return (
                  <tr key={e.id}>
                    <td>
                      <NavLink to={`/single-product/${e.id}`}>
                        <img
                          src={cleanedImageUrl}
                          className="cart_body_details_img"
                          alt="Product img"
                        />
                      </NavLink>
                    </td>
                    <td>
                      <p>{cleanSlug}</p>
                      <p>Price : ${e.price}</p>
                      <p>Quantity : {e.qnty}</p>
                    </td>
                    <td
                      className="cart_body_details_dlt"
                      onClick={() => dlt(e.id)}
                    >
                      <MdDelete />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="row">
            <Col lg="5">
              <p>Total: ${price}</p>
            </Col>
            <Col lg="3">
              <button className="custom_btn_card">
                <Link to="/cart">Cart</Link>
              </button>
            </Col>
            <Col lg="4">
              <button className="custom_btn_card">
                <Link to="/checkOut">Check Out</Link>
              </button>
            </Col>
          </div>
        </div>
      ) : (
        <div className="cart_body">
          <i
            onClick={handleClose}
            style={{
              position: "absolute",
              top: 2,
              right: 20,
              fontSize: 23,
              cursor: "pointer",
            }}
          >
            <IoClose />
          </i>
          <p>Your cart is empty</p>
          <div className="emptycart_img">
            <MdRemoveShoppingCart />
          </div>
        </div>
      )}
    </Menu>
  );
};

export default CartBody;

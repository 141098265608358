import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD } from "../Redux/actions/CartAction";
import NoImage from "../images/NoImage.jpg";
import { FaStar } from "react-icons/fa";
import Swal from "sweetalert2";

const ProductListing = ({ sortedProducts }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.user);

  const handleAddToCart = (product) => {
    if (isLoggedIn) {
      dispatch(ADD(product));
      Swal.fire({
        title: "Success!",
        text: "Added to Cart successfully",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        title: "Please Login",
        text: "Please login to add the product to your cart.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Login",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/login";
        }
      });
    }
  };

  return (
    <>
      <div className="cards">
        {sortedProducts.map(
          ({
            id,
            price,
            slug,
            special_price,
            long_description,
            imagestwo,
            sku,
            images,
          }) => {
            const cleanSlug = slug.replace(/-/g, " ");
            const matchingImage = images.find((image) => image.sku === sku);
            const displayImage = matchingImage
              ? matchingImage.image_url
              : NoImage;

            return (
              <Card className="all_card" key={id}>
                <Link to={"/single-product/" + id}>
                  <Card.Img
                    className="card_img"
                    variant="top"
                    src={displayImage}
                    alt="Product img"
                  />
                </Link>
                <Card.Body>
                  <div className="star">
                    {[...Array(5)].map((_, index) => (
                      <i key={index}>
                        <FaStar />
                      </i>
                    ))}
                  </div>
                  <Card.Title>{cleanSlug}</Card.Title>
                  <div
                    className="long_description"
                    dangerouslySetInnerHTML={{ __html: long_description }}
                  />
                  <div className="card_footer">
                    <div className="card_price">
                      <p className="discount_price">${price}</p>
                      {special_price && <p>{special_price}</p>}
                    </div>
                    <button
                      className="custom_btn_card"
                      onClick={() =>
                        handleAddToCart({
                          id,
                          price,
                          slug,
                          special_price,
                          long_description,
                          imagestwo,
                        })
                      }
                    >
                      <Link to="#">Add To Cart</Link>
                    </button>
                  </div>
                </Card.Body>
              </Card>
            );
          }
        )}
      </div>
    </>
  );
};

export default ProductListing;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "../images/categories-bg.png";
import Image0 from "../images/category-img-0.png";
import Image1 from "../images/category-img-1.png";
import Image2 from "../images/category-img-3.png";
import { Link } from "react-router-dom";

const Category = () => {
  return (
    <>
      <div className="category-bg">
        <div className="marquee-container-2 category-bg-img">
          <img className="" src={Image} alt="" />
        </div>
        <Container>
          <div className="my-container">
            <Row>
              <div className="category-title">
                <h1>Categories</h1>
              </div>

              <Col lg="4">
                <Link to="/WizardScreen">
                  <div className="category-img">
                    <img src={Image0} alt="" />
                    <p className="category-img-p">Wizard Screens</p>
                  </div>
                </Link>
              </Col>
              <Col lg="4">
                <Link to="/CreteCast">
                  <div className="category-img">
                    <img src={Image1} alt="" />
                    <p className="category-img-p">Crete Cast Products</p>
                  </div>
                </Link>
              </Col>
              <Col lg="4">
                <div className="category-img">
                  <img src={Image2} alt="" />
                  <p className="category-img-p">ESP Low-E Insulation</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Category;

import { FETCH_SETTINGS_REQUEST, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE } from '../actions/settingsActions';

const initialState = {
  settings: [],
  loading: false,
  error: null
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload
      };
    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default settingsReducer;

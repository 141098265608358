import React, { useEffect } from 'react';

const PayPalButton = () => {
  useEffect(() => { 
    // Initialize the Braintree client with your live client ID
    const client = new window.braintree.client({
      authorization: 'AXyE9yES5FCc2zpKX2oHod_OE2r25jNaM_434FN2ERC8pHjdzU-f9cizDM1HJIBnKh5UxJWFSTK_EQq3',
    });

    // Create a PayPal Checkout component
    window.braintree.paypalCheckout.create({
      client, 
    }, (paypalCheckoutInstance) => {
      // Set up a PayPal button
      // paypal.Button.render({
      //   env: 'production', // Use 'sandbox' for testing, 'production' for live transactions
      //   commit: true,
      //   payment: (data, actions) => {
      //     // Make a call to your server to set up the payment
      //     return actions.request({
      //       method: 'post',
      //       url: '/your-server-endpoint-for-creating-paypal-transaction',
      //     })
      //     .then(response => {
      //       // Return the ID of the created payment
      //       return response.id;
      //     });
      //   },
      //   onAuthorize: (data, actions) => {
      //     // Make a call to your server to execute the payment
      //     return actions.request({
      //       method: 'post',
      //       url: '/your-server-endpoint-for-executing-paypal-transaction',
      //       data: {
      //         paymentID: data.paymentID,
      //         payerID: data.payerID,
      //       },
      //     })
      //     .then(response => {
      //       // Handle the payment success
      //       console.log('Payment Complete!', response);
      //     });
      //   },
      // }, '#paypal-button-container');
    });
  }, []);

  return (
    <div id="paypal-button-container"></div>
  );
}

export default PayPalButton;

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import filterReducers from './reducer/filterReducers';
import { cartreducer } from './reducer/CartReducer';
import authReducer from './reducer/authReducer';
import settingsReducer from './reducer/settingsReducer';

const rootReducer = combineReducers({
  shop: filterReducers,
  // carts: cartReducer
  auth: authReducer,
  settings: settingsReducer,
  cartreducer
});

const userData = JSON.parse(localStorage.getItem('user'));

const initialState = {
  auth: {
    user: userData,
    userId: userData ? userData.id : null,
    error: null,
    isAuthenticated: !!userData
  }
};

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
);

export default store;







// import { createStore, combineReducers } from 'redux';
// import filterReducers from './reducer/filterReducers';
// import { cartreducer } from './reducer/CartReducer';
// import authReducer from './reducer/authReducer';
// import settingsReducer from './reducer/settingsReducer';

// const rootReducer = combineReducers({
//   shop: filterReducers,
//   // carts: cartReducer
//   auth: authReducer,
//   settings: settingsReducer,
//   cartreducer
// });
// const userData = JSON.parse(localStorage.getItem('user'));

// const initialState = {
//   auth: {
//     user: userData,
//     userId: userData ? userData.id : null,
//     error: null,
//     isAuthenticated: !!userData
//   }
// };

// const store = createStore(
//   rootReducer,
//   initialState,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// export default store;
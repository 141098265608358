import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import NoImage from "../images/latest-blogs-2.png";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "./Loading ";
import HeaderImage2 from "../images/banner-side -image.png";
import HeaderImage from "../images/main-banner.png";
import SectionHeader from "./SectionHeader";

const SingleBlog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseURL = "https://backend.bolt-corp.devhub.digtalsdesigns.com/public/";

  useEffect(() => {
    axios
      .get(`https://backend.bolt-corp.devhub.digtalsdesigns.com/api/blog/${id}`)
      .then((response) => {
        console.log("Blog data:", response.data);
        setBlog(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the blog data!", error);
        setError(error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="spinner-container">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <p>There was an error loading the blog: {error.message}</p>;
  }

  if (!blog) {
    return <p>No blog data available.</p>;
  }

  return (
    <>
    <SectionHeader
        title="Single Blog"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>
      <Container className="single-blog-container">
        <Row>
          <Col md={6}>
            <div className="right_side">
              {blog.image ? (
                <img
                  className="img-fluid"
                  src={`${baseURL}${blog.image}`}
                  alt={blog.title}
                />
              ) : (
                <img className="img-fluid" src={NoImage} alt="No blog img" />
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="left_side">
              <h1>{blog.title}</h1>
              <p>{blog.date}</p>
              <div
                className="single_blog"
                dangerouslySetInnerHTML={{ __html: blog.description }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SingleBlog;

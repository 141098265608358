import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../images/collection-1.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../Redux/actions/settingsActions";

const Collection = () => {

  const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);

  const collections_first_title = settings.find(setting => setting.name === 'collections_first_title');
  const collections_second_title = settings.find(setting => setting.name === 'collections_second_title');
  const collections_img = settings.find(setting => setting.name === 'collections_img');
    

    const baseImageUrl = 'https://backend.bolt-corp.devhub.digtalsdesigns.com/public/uploads/media/';
    const imageUrl = collections_img?.value ? `${baseImageUrl}${collections_img.value}` : '';

  return (
    <>
      <div className="collection-main">
        <Container>
          <div className="my-container">
            <div className="collection-body">
              <p className="collection-body-sub-tital">{collections_first_title?.value}</p>
              <h1 className="collection-body-titla">
                {collections_second_title?.value}
              </h1>
              <button className="custom_btn ">
                <Link to="/">Shop Now</Link>{" "}
              </button>
              <div className="collection-body-img">
              <img src={imageUrl} alt="" data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Collection;

import React from "react";
import AboutUs from "../../components/AboutUs";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import HeaderImage2 from "../../images/banner-side -image.png";

const About = () => {
  return (
    <>
      <SectionHeader
        title="About Us"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>
      <AboutUs hideClasses={true} />
    </>
  );
};

export default About;

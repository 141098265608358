import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NoImage from "../images/latest-blogs-2.png";
import Image from "../images/latest-blogs.png";
import { Card, Container } from "react-bootstrap";
import { FaUser } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import axios from "axios";

const Blogs = () => {
  const [blog, setBlog] = useState([]);
  const baseURL = "https://backend.bolt-corp.devhub.digtalsdesigns.com/public/";
  useEffect(() => {
    axios
      .get("https://backend.bolt-corp.devhub.digtalsdesigns.com/api/blog-show")
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="blog-bg">
        <div className="latest-blogs-main">
          <div className="marquee-container-2 latest-blogs-img">
            <img className="" src={Image} alt="" />
          </div>
          <Container className="blog-post">
            <div className="my-container className=blog-post">
              <div className="latest-blogs-title">
                <h1>LATEST BLOGS</h1>
              </div>
              <div
                className="blog-posts"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
                data-aos-easing="linear"
              >
                {blog.map(({ id, image, date, title, description }) => {
                  return (
                    <Card className="blog_card" key={id}>
                      {image ? (
                        <Card.Img
                          className="blog_card_img"
                          variant="top"
                          src={`${baseURL}${image}`}
                        />
                      ) : (
                        <Card.Img
                          className="blog_card_img"
                          variant="top"
                          src={NoImage}
                          alt="Product img"
                        />
                      )}
                      <Card.Body className="blog_text">
                        <div className="date_admin">
                          <Link to="#">
                            <p>{date}</p>
                          </Link>
                          <Link to="#">
                            <p className="bolg_title">{title}</p>
                          </Link>
                        </div>
                        <p className="blog_text_truncate">
                          <div
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </p>
                        <Link to={`/blog/${id}`}>
                          <p className="read-more">Read more</p>
                        </Link>
                      </Card.Body>
                    </Card>
                  );
                })}
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Blogs;

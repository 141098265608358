import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const SearchResultsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get("search");

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/search-products`, {
          params: { search: searchQuery },
        });

        // Log the response for debugging
        console.log(response.data);

        if (response.data && response.data.data) {
          setProducts(response.data.data);
        } else {
          setError("No products found");
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError("No products found");
        } else {
          setError("Error fetching products");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchQuery]);

  return (
    <div className="search-results-page">
      <h1>Search Results for "{searchQuery}"</h1>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && products.length > 0 ? (
        <div className="product-list">
          {products.map(({ id, name, category, price, image }) => (
            <div key={id}>
              <h2>{name}</h2>
              <p>Category: {category?.name}</p>
              <p>Price: ${price}</p>
              <img src={image} alt={name} />
            </div>
          ))}
        </div>
      ) : (
        !loading && <p>No products found for your search.</p>
      )}
    </div>
  );
};

export default SearchResultsPage;

import React from 'react'
import HeaderImage from '../../../images/section-herder-img.jpg'
import SectionHeader from '../../../components/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'


const Invoice = () => {
    return (
        <>
            <SectionHeader title='Invoice' image={HeaderImage} PageLink='Invoice'></SectionHeader>
            <div className="about-bg">
                <Container className='padding'>
                    <Row className='receipt-content'>
                        <Col lg={10}>
                            <div className='invoice-wrapper'>
                                <div className="payment-info">
                                    <Row>
                                        <Col lg={6}>
                                            <span>Order ID : <b>54</b></span>
                                            <br />
                                            <span>Order Date : <b>2024-01-23</b></span>
                                            <br />
                                            <span>Status : <b className='badge badge-danger'>Pending Payment</b></span>
                                        </Col>
                                        <Col lg={6} className='text-right-print'>
                                            <span>Payment Status<br /> <b className='badge badge-danger'>Pending</b></span>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="payment-details">
                                    <Row>
                                        <Col lg={4}>
                                                <h4>Customer Details</h4>
                                        </Col>
                                        <Col lg={4}>
                                                <h4>Shipping Address</h4>
                                        </Col>
                                        <Col lg={4}>
                                                <h4>Payment To</h4>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Invoice
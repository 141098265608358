import React, { useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../login/login.css";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    phone: "", // Add phone field
    image: null, // Add image field for file upload
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0], // Handle file input
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.cpassword) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Password & Confirm Password do not match",
      });
      return;
    }

    if (!formData.name || !formData.email || !formData.password) {
      Swal.fire("Please fill out all fields.");
      return;
    }

    if (formData.password.length < 4) {
      Swal.fire("Password must be at least 4 characters long.");
      return;
    }

    try {
      let baseUrl =
        process.env.REACT_APP_BASE_URL ||
        "https://backend.bolt-corp.devhub.digtalsdesigns.com";
      const formPayload = new FormData();

      // Append form fields
      formPayload.append("name", formData.name);
      formPayload.append("email", formData.email);
      formPayload.append("password", formData.password);
      formPayload.append("phone", formData.phone); // Append phone field
      formPayload.append("image", formData.image); // Append file

      const response = await axios.post(
        baseUrl + "/api/register",
        formPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Required for file upload
          },
        }
      );

      if (response.status === 201) {
        Swal.fire("User registered successfully.");
        // Store userInfo in localStorage
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        navigate("/login");
      } else {
        throw new Error("Registration failed");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Error", error.message, "error");
    }
  };

  return (
    <>
      <section className="login-bg">
        <div className="main_login_signup">
          <Container>
            <Row className="row_login_signup">
              <Col lg={6}>
                <div className="main_lognin">
                  <form onSubmit={handleSubmit}>
                    <h1>Register to your account</h1>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                    />
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Your Phone Number"
                    />
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Your Password"
                    />
                    <input
                      type="password"
                      name="cpassword"
                      value={formData.cpassword}
                      onChange={handleChange}
                      placeholder="Confirm Password"
                    />
                    <input
                      type="file"
                      name="image"
                      onChange={handleChange}
                      accept="image/*"
                    />
                    <button type="submit" className="theme-btn">
                      SignUp
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Register;

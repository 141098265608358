import React, { useState, useEffect } from "react";
import { HiArrowLongDown } from "react-icons/hi2";

const ScrollDown = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 700) {
        setShowTopBtn(false);
      } else {
        setShowTopBtn(true);
      }
    });
  }, []);
  const goToDown = () => {
    window.scrollTo({
      top: 1000,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-to-btm">
        {" "}
        {showTopBtn && (
          <HiArrowLongDown
            className="icon-position2 icon-style2"
            onClick={goToDown}
          />
        )}{" "}
      </div>
      <p className="bottom-to-text">
        Scroll
        <br />
        Down
      </p>
    </>
  );
};

export default ScrollDown;

import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import Image1 from "../../images/prod-menu-img1-resize.png";
import Image2 from "../../images/prod-menu-img2-resize.png";
import Image3 from "../../images/prod-menu-img3-resize.png";
import Image4 from "../../images/prod-menu-img4-resize.png";
import HeaderImage2 from "../../images/banner-side -image.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const USCabinet = () => {
  return (
    <>
      <SectionHeader
        title="US Cabinet"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>

      <Container className="aling">
        <Row>
          <Col md={12}>
            <div className="products">
              <Row>
                <Col md={3}>
                  <div className="product-card">
                    <div className="Image">
                      <Link to="https://www.uscabinetdepot.com/framed-capital-collection">
                        <img src={Image1} alt="" />
                      </Link>
                    </div>
                    <div className="product-title">
                      <span>Framed Cabinetry</span>
                      <div className="poduct-price">
                        <span>Capital Collection</span>
                      </div>
                    </div>
                    <a href="https://www.uscabinetdepot.com/framed-capital-collection">
                      Learn More
                    </a>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="product-card">
                    <div className="Image">
                      <Link to="https://www.uscabinetdepot.com/framed-capital-impressions-collection">
                        <img src={Image2} alt="" />
                      </Link>
                    </div>
                    <div className="product-title">
                      <span>Framed Cabinetry</span>
                      <div className="poduct-price">
                        <span>Capital Impressions Collection</span>
                      </div>
                    </div>
                    <a href="https://www.uscabinetdepot.com/framed-capital-impressions-collection">
                      Learn More
                    </a>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="product-card">
                    <div className="Image">
                      <Link to="https://www.uscabinetdepot.com/alpine-series-frameless-cabinets/">
                        <img src={Image3} alt="" />
                      </Link>
                    </div>
                    <div className="product-title">
                      <span>Framed Cabinetry</span>
                      <div className="poduct-price">
                        <span>altaeuro collection</span>
                      </div>
                    </div>
                    <a href="https://www.uscabinetdepot.com/alpine-series-frameless-cabinets/">
                      Learn More
                    </a>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="product-card">
                    <div className="Image">
                      <Link to="https://www.uscabinetdepot.com/rev-a-shelf-cabinet-accessories">
                        <img src={Image4} alt="" />
                      </Link>
                    </div>
                    <div className="product-title">
                      <span>Framed Cabinetry</span>
                      <div className="poduct-price">
                        <span>Rev-A-Shelf® Accesories</span>
                      </div>
                    </div>
                    <a href="https://www.uscabinetdepot.com/rev-a-shelf-cabinet-accessories">
                      Learn More
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default USCabinet;

import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading ';

const Contact = () => {
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <Loading />
      </div>
    );
  }
    return (
        <>
           <h1>Contact</h1>
        </>
    )
}

export default Contact
import React, { useCallback, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DLT } from '../Redux/actions/CartAction';
import Badge from '@mui/material/Badge';
import CartBody from './CartBody';
import { FaCartShopping } from 'react-icons/fa6';

const HeaderNav = () => {
    const [price, setPrice] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const getdata = useSelector((state) => state.cartreducer.carts);
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);


    

    

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dlt = (id) => {
        dispatch(DLT(id))
    }

    const total = useCallback(() => {
        let totalPrice = 0;
        getdata.forEach((ele, k) => {
            totalPrice = ele.price * ele.qnty + totalPrice;
        });
        setPrice(totalPrice);
    }, [getdata]);

    useEffect(() => {
        total();
    }, [total])
    return (
        <>
            <Navbar expand="lg" className="nav_bar" data-bs-theme="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div className='row' >
                        <div className="header_input">
                            <NavLink to='#'>
                                <Badge badgeContent={getdata.length} color="primary"    
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <i className='header_input_icon' ><FaCartShopping /></i>
                                </Badge>
                            </NavLink>
                        </div>
    
                    </div>
                </Navbar.Collapse>
            </Navbar>
            <CartBody
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                getdata={getdata}
                dlt={dlt}
                price={price}
            />
        </>
    )
}

export default HeaderNav;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Loading from "../../components/Loading ";
import moment from "moment";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let userInfo = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://backend.bolt-corp.devhub.digtalsdesigns.com/api/orders/" +
            userInfo.id
        );
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setError("Failed to fetch orders. Please try again later.");
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div className="dash-card about-bg">
      <Card className="dash-sub-card">
        <Card.Header as="h4" className="dash-card-header">
          My Orders
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Order Date</th>
                <th>Cutomer Name</th>
                <th>Order Status</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) =>
                order.products.map((product, index) => (
                  <tr key={index}>
                    {index === 0 && (
                      <>
                        <td rowSpan={order.products.length}>
                          {order.order_id}
                        </td>
                        <td rowSpan={order.products.length}>
                          {moment(order.order_date).format("DD-MM-YYYY")}
                        </td>
                        <td rowSpan={order.products.length}>
                          {order.customer_name}
                        </td>
                        <td rowSpan={order.products.length}>
                          {order.order_status}
                        </td>
                      </>
                    )}
                    <td>{product.slug}</td>
                    <td>{product.quantity}</td>
                    <td>{product.price}</td>
                    <td rowSpan={order.products.length}>{order.total}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Orders;

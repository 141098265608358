import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MyPaymentForm from '../myPaymentForm/MyPaymentForm';

const stripePromise = loadStripe('pk_test_51MgbxjEilGowYMOJ9pZ7ZMuer7So6zuTCOcnoKa7A7YdH8ZJ9XOdb8j6xa9Qo3bE3O7FsOiXcTRZoFoOyekMIf0h00szPnwgCA');

function StripePaymentComponent() {
  useEffect(() => {
    const initializeStripe = async () => {
      const stripe = await stripePromise; 
    };

    initializeStripe();
  }, []);

  const styles = {
    padding: '30px 0px'
  };

  return (
    <>
    <div style={styles}>
    <Elements stripe={stripePromise}>
      <MyPaymentForm />
    </Elements>
    </div>
    </>
  );
}

export default StripePaymentComponent;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import HeroImg from "../images/banner-bg-img.png";
import HeroImg0 from "../images/banner-text.png";
import HeroImg2 from "../images/banner-circle.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollDown from "./ScrollDown";

const HeroSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="main_hero_slider">
        <div className="hero-slider">
          <Slider {...settings}>
            <div className="hero-slider_img_bg">
              <div className="hero_img">
                <img className="hero_img0" src={HeroImg} alt="" />

                <div className="marquee-container">
                  <img className="hero_img_text" src={HeroImg0} alt="" />
                </div>

                <img
                  className="hero_img2"
                  src={HeroImg2}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                  data-aos-easing="linear"
                />
              </div>
            </div>

            <div className="hero-slider_img_bg">
              <div className="hero_img">
                <img className="hero_img0" src={HeroImg} alt="" />
                <div className="marquee-container">
                  <img className="hero_img_text" src={HeroImg0} alt="" />
                </div>
                <img
                  className="hero_img2"
                  src={HeroImg2}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
            <div className="hero-slider_img_bg">
              <div className="hero_img">
                <img className="hero_img0" src={HeroImg} alt="" />
                <div className="marquee-container">
                  <img className="hero_img_text" src={HeroImg0} alt="" />
                </div>
                <img
                  className="hero_img2"
                  src={HeroImg2}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
            <div className="hero-slider_img_bg">
              <div className="hero_img">
                <img className="hero_img0" src={HeroImg} alt="" />
                <div className="marquee-container">
                  <img className="hero_img_text" src={HeroImg0} alt="" />
                </div>
                <img
                  className="hero_img2"
                  src={HeroImg2}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
            <div className="hero-slider_img_bg">
              <div className="hero_img">
                <img className="hero_img0" src={HeroImg} alt="" />
                <div className="marquee-container">
                  <img className="hero_img_text" src={HeroImg0} alt="" />
                </div>
                <img
                  className="hero_img2"
                  src={HeroImg2}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </Slider>
          <ScrollDown />
        </div>
      </div>
    </>
  );
};

export default HeroSlider;

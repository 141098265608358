import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const SectionHeader = ({ title, image, image2, title2, content, icon }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <header className="header">
        <div className="header__container">
          <div
            className="header__container-bg "
            data-aos="zoom-in-up"
            data-aos-duration="3500"
            data-aos-easing="linear"
          >
            <img src={image} alt="Header background" />
          </div>
          <div
            className="header__content container"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>{title}</h1>
          </div>
        </div>
        <div
          className="header__content1 container"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h4>{title2}</h4>
          <p>{content}</p>
          {icon && <FontAwesomeIcon icon={FaSquareArrowUpRight} />}
        </div>

        <span className="header_img">
          <div
            className="header__top-bg "
            data-aos="zoom-in-up"
            data-aos-duration="3500"
            data-aos-easing="linear"
          >
            <img src={image2} alt="Header background" />
          </div>
        </span>
      </header>
      <div className="arrow-direction-main">
        <div className="arrow-direction">
          <a href="/">
            <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </div>
      </div>
    </>
  );
};

export default SectionHeader;
